import React from 'react';
import {
  DialogTitle,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';
import * as Styled from './styles';

interface Props {
  open: boolean;
  handleClose: () => void;
  dialogTitle: string;
  dialogContent: string;
  dialogConfirmAction: () => void;
  dialogConfirmLabel: string;
  dialogCancelAction: () => void;
  dialogCancelLabel: string;
  loading?: boolean;
}

export const Dialog: React.FC<Props> = ({
  open,
  handleClose,
  dialogTitle,
  dialogContent,
  dialogConfirmAction,
  dialogConfirmLabel,
  dialogCancelAction,
  dialogCancelLabel,
  loading,
}) => {
  return (
    <Styled.Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">{dialogTitle}</DialogTitle>
      <Styled.DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
        <Styled.DialogActions>
          {loading ? (
            <CircularProgress color="inherit" size={18} />
          ) : (
            <>
              <Styled.ConfirmButton
                data-cy="dialog-confirm-button"
                onClick={dialogConfirmAction}
              >
                {dialogConfirmLabel}
              </Styled.ConfirmButton>
              <Styled.CancelButton onClick={dialogCancelAction}>
                {dialogCancelLabel}
              </Styled.CancelButton>
            </>
          )}
        </Styled.DialogActions>
      </Styled.DialogContent>
    </Styled.Dialog>
  );
};
