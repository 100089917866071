import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  margin: 1rem 0;
`;

export const Heading = styled(Typography).attrs({
  variant: 'caption',
  component: 'p',
})`
  margin: 0.5rem 0;
`;

export const List = styled.ul`
  list-type: bullet;
  margin: 0;
  padding: 0 0 0 1.5rem;
`;

export const ListItem = styled(Typography).attrs({
  variant: 'caption',
  component: 'li',
})`
  margin-bottom: 0.3rem;
`;
