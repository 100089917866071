import { useTranslation } from 'react-i18next';
import { useGlobalState } from '~/context/global';
import { SelectOptions } from '~/components/forms/fields/Select';
import { useForm, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { VaccinationValue } from '~/common/vaccinations';

export interface VaccinationsForm {
  receivedFluVaccine: VaccinationValue;
  receivedCovidVaccine: VaccinationValue;
}

interface UseVaccinationsForm {
  form: UseFormMethods<VaccinationsForm>;
  validationSchema: Map<keyof VaccinationsForm, RegisterOptions>;
  getErrorMessage: (field: keyof VaccinationsForm) => string | undefined;
}

export const useVaccinationsForm = (): UseVaccinationsForm => {
  const { t } = useTranslation();

  const vaccinations = useGlobalState().user?.account?.vaccinations;

  const form = useForm<VaccinationsForm>({
    defaultValues: {
      receivedFluVaccine:
        vaccinations?.flu !== VaccinationValue.UNKNOWN
          ? vaccinations?.flu
          : undefined,
      receivedCovidVaccine:
        vaccinations?.covid !== VaccinationValue.UNKNOWN
          ? vaccinations?.covid
          : undefined,
    },
  });

  const validationSchema = new Map<keyof VaccinationsForm, RegisterOptions>();

  const isUnknown = (vaccinationValue: VaccinationValue) =>
    vaccinationValue === VaccinationValue.UNKNOWN;

  const fluVaccineOptions: SelectOptions = t('FormField.fluVaccine.options', {
    returnObjects: true,
  });

  validationSchema.set('receivedFluVaccine', {
    validate: {
      options: value => {
        if (!value || isUnknown(value)) {
          return true;
        }
        return fluVaccineOptions.some(opt => opt.value === value);
      },
    },
  });

  const covidVaccineOptions: SelectOptions = t(
    'FormField.covidVaccine.options',
    { returnObjects: true }
  );

  validationSchema.set('receivedCovidVaccine', {
    validate: {
      options: value => {
        if (!value || isUnknown(value)) {
          return true;
        }
        return covidVaccineOptions.some(opt => opt.value === value);
      },
    },
  });

  const getErrorMessage = (fieldName: keyof VaccinationsForm): string => {
    const errType = form.errors?.[fieldName]?.type;
    return errType ? t(`FormField.${fieldName}.errors.${errType}`) : '';
  };

  return { form, validationSchema, getErrorMessage };
};
