import { useEffect } from 'react';
import { emailPattern } from '~/common/regex';
import { useGlobalState } from '~/context/global';
import { useForm, RegisterOptions, UseFormMethods } from 'react-hook-form';

export interface ChangeEmailForm {
  currentEmail: string;
  newEmail: string;
}

interface UseChangeEmail {
  form: UseFormMethods<ChangeEmailForm>;
  validationSchema: Map<keyof ChangeEmailForm, RegisterOptions>;
}

export const useChangeEmail = (): UseChangeEmail => {
  const currentEmail = useGlobalState().user?.account?.email;

  const form = useForm<ChangeEmailForm>({
    defaultValues: {
      currentEmail,
      newEmail: '',
    },
  });

  const validationSchema = new Map<keyof ChangeEmailForm, RegisterOptions>();
  validationSchema.set('newEmail', {
    required: true,
    pattern: emailPattern,
    validate: {
      sameAsCurrent: newEmail => newEmail !== form.getValues('currentEmail'),
    },
  });

  useEffect(
    function autoFillForm() {
      form.reset({ currentEmail });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEmail]
  );

  return { form, validationSchema };
};
