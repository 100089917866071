import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const PageTitle = styled(Typography).attrs({
  variant: 'h1',
})`
  margin: 1rem 0;
  font-size: 32px;
  font-weight: 500;
`;
