import styled from 'styled-components';
import MuiFormControl from '@material-ui/core/FormControl';
import { Grid, Button, Typography, TextField } from '@material-ui/core';
import { BaseInput } from '~/components/forms/fields';

export const FormContainer = styled.div``;

export const FnyMessageContainer = styled(Grid).attrs({
  item: true,
})`
  margin: 1em 0;
`;

export const FormControl = styled(MuiFormControl).attrs({
  variant: 'outlined',
})``;

export const RemindersQuestion = styled(Typography).attrs({
  align: 'left',
  variant: 'body1',
})`
  margin: 1rem 0 0.5rem;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;

export const WarningCaption = styled(Typography).attrs({
  align: 'left',
  variant: 'caption',
})`
  margin-top: 1rem;
`;

export const FormActionsContainer = styled(Grid).attrs({
  spacing: 2,
  container: true,
  direction: 'column',
  justify: 'flex-end',
})`
  padding: 2rem 0 1.5rem;
`;

export const SubmitButton: typeof Button = styled(Button).attrs({
  fullWidth: true,
  color: 'primary',
  variant: 'contained',
})`
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
`;

export const CancelButton: typeof Button = styled(SubmitButton).attrs({
  variant: 'outlined',
})``;

export const FormActionsFooter = styled(Grid)`
  margin-top: 1rem;
  text-align: center;
`;

export const FormActionsLink: typeof Typography = styled(Typography).attrs({
  align: 'center',
  color: 'primary',
})`
  display: inline-block;
  margin: 0.75rem 0 0.25rem;
  text-decoration: underline;
  transition: color 0.3s ease;
  &:hover,
  &:focus {
    color: ${props => props.theme.palette.primary.dark200};
  }
`;

export const PwdInput: typeof TextField = styled(BaseInput).attrs({
  type: 'password',
})``;
