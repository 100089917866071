import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import {
  Box,
  IconButton,
  Modal as UnstyledModal,
  Typography,
} from '@material-ui/core';

export const ModalTitle = styled(Typography).attrs({ variant: 'h2' })`
  margin: 0 0 1.5rem;
  font-size: 1.5rem;
  font-weight: 500;
`;

export const ModalContent = styled(Box).attrs({
  boxShadow: 8,
})`
  position: fixed;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  padding: 2.5rem 1.5rem 3rem;
  overflow: auto;
  background-color: ${props => props.theme.palette.common.white};
  ${breakpoint.md`
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
  `}
`;

export const Modal = styled(UnstyledModal)``;

export const CloseModalButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;
