import React, { useState } from 'react';
import { Dialog } from '../Dialog';
import { useTranslation } from 'react-i18next';
import { useGlobalDispatch } from '~/context/global';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { logger, ONMFeature } from '~/services/logging';
import { HouseholdMember } from '~/context/global/user/account';
import {
  removeHouseholdMember,
  RemoveHouseholdMemberRequestBody,
} from '~/requests/household';

interface Props {
  open: boolean;
  member: HouseholdMember;
  handleClose: () => void;
}

export const RemoveHouseholdMemberDialog: React.FC<Props> = ({
  open,
  member,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const dispatch = useGlobalDispatch();
  const { t } = useTranslation();
  const tPath = 'HouseholdMembers';

  const submitRemoveHouseholdMember = async () => {
    setLoading(true);

    try {
      const reqBody: RemoveHouseholdMemberRequestBody = {
        householdMemberID: member.id,
        status: false,
      };

      await removeHouseholdMember(reqBody);
      dispatch({ type: 'DEACTIVATE_HOUSEHOLD_MEMBER', payload: member.id });
      handleClose();
    } catch (error) {
      logger.error(ONMFeature.REMOVE_HOUSEHOLD_MEMBER, error);
      showSnackbar(
        t(`${tPath}.householdMemberError`, {
          memberName: member.name,
          action: 'remove',
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const cancelRemoveHouseholdMember = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      loading={loading}
      handleClose={handleClose}
      dialogCancelAction={cancelRemoveHouseholdMember}
      dialogConfirmAction={submitRemoveHouseholdMember}
      dialogConfirmLabel={t(`${tPath}.confirmRemoveMemberButtonLabel`)}
      dialogCancelLabel={t(`${tPath}.form.cancelButtonLabel`)}
      dialogTitle={t(`${tPath}.removeMemberDialogTitle`, {
        memberName: member.name,
      })}
      dialogContent={t(`${tPath}.removeMemberDialogContent`, {
        memberName: member.name,
      })}
    />
  );
};
