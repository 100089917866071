import React from 'react';
import { useTranslation } from 'react-i18next';
import { Delete, Edit } from '@material-ui/icons';
import { HouseholdMember } from '~/context/global/user/account';
import { TranslatedSelectField } from '~/common/types/translation';
import {
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableBody as MuiTableBody,
} from '@material-ui/core';
import * as Styled from './styles';

interface Props {
  householdMembers: HouseholdMember[];
  onEditButtonClick: (member: HouseholdMember) => void;
  onRemoveButtonClick: (member: HouseholdMember) => void;
}

export const HouseholdMembersTable: React.FC<Props> = ({
  householdMembers,
  onEditButtonClick,
  onRemoveButtonClick,
}) => {
  const { t } = useTranslation();

  const tPath = 'HouseholdMembers';

  const tableHeadings: [string] = t(`${tPath}.tableHeadings`, {
    returnObjects: true,
  });

  const raceField: TranslatedSelectField = t(`${tPath}.form.raceId`, {
    returnObjects: true,
  });

  const ethnicities: TranslatedSelectField = t(`${tPath}.table.ethnicityId`, {
    returnObjects: true,
  });

  const getMemberDateOfBirthString = (dob: string) => {
    const months = t('Months', { returnObjects: true });
    const [year, month] = dob.split('-');
    return `${months[month]} ${year}`;
  };

  const getMemberRace = (member: HouseholdMember) => {
    const raceOption = raceField.options.find(
      opt => opt.value === `${member?.userRace?.race?.id}`
    );
    return raceOption?.label;
  };

  const getMemberEthnicity = (member: HouseholdMember) => {
    const ethnicityId = member.userEthnicity?.ethnicity.id;
    return ethnicities[ethnicityId?.toString()] ?? '';
  };

  return (
    <Styled.Table>
      <TableHead>
        <TableRow>
          {tableHeadings.map((heading, i) => (
            <Styled.TableHeading key={i}>{heading}</Styled.TableHeading>
          ))}
          <Styled.TableHeading> </Styled.TableHeading>
        </TableRow>
      </TableHead>
      <MuiTableBody>
        {householdMembers
          .filter(member => member.isActive)
          .map((member, i) => (
            <TableRow key={i}>
              <TableCell>{member.name}</TableCell>
              <TableCell>
                {getMemberDateOfBirthString(member.dateOfBirth)}
              </TableCell>
              <TableCell>{t(`${tPath}.gender.${member.gender}`)}</TableCell>
              <TableCell>{getMemberRace(member)}</TableCell>
              <TableCell>{getMemberEthnicity(member)}</TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="edit household member information"
                  onClick={() => onEditButtonClick(member)}
                  data-cy="edit-household-member-button"
                >
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="remove household member"
                  onClick={() => onRemoveButtonClick(member)}
                  data-cy="delete-household-member-button"
                >
                  <Delete fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </MuiTableBody>
    </Styled.Table>
  );
};
