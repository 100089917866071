import styled from 'styled-components';
import {
  Button,
  Dialog as UnstyledDialog,
  DialogContent as UnstyledDialogContent,
} from '@material-ui/core';

export const Dialog = styled(UnstyledDialog)``;

export const DialogContent = styled(UnstyledDialogContent)`
  padding-bottom: 2rem;
`;

export const DialogActions = styled.div`
  margin: 1.5rem 0 0;
`;

export const ConfirmButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
`;

export const CancelButton = styled(Button).attrs({
  variant: 'text',
})`
  margin-left: 0.5rem;
  color: ${props => props.theme.palette.gray.mid};
`;
