import routes from '../routes';
import { snakecase } from '~/common/casing';
import { httpClient, Response } from '~/services/http';
import {
  HouseholdMember,
  Gender,
  Race,
  Ethnicity,
} from '~/context/global/user/account';

export interface AddHouseholdMemberRequestBody {
  name: string;
  dateOfBirth: string;
  gender: Gender;
  raceId: Race;
  userSubmittedRace: string;
  ethnicityId: Ethnicity;
}

export const addHouseholdMember = (
  requestBody: AddHouseholdMemberRequestBody
): Promise<Response<HouseholdMember>> => {
  return httpClient.post(routes.householdMember, snakecase(requestBody));
};

export interface EditHouseholdMemberRequestBody {
  id: number;
  name: string;
  gender: Gender;
  raceId: Race;
  ethnicityId: Ethnicity;
  userSubmittedRace: string;
  dateOfBirth: string;
}

export const editHouseholdMember = (
  requestBody: EditHouseholdMemberRequestBody
): Promise<Response<HouseholdMember>> => {
  return httpClient.put(routes.householdMember, snakecase(requestBody));
};

export interface RemoveHouseholdMemberRequestBody {
  householdMemberID: number;
  status: boolean;
}

export const removeHouseholdMember = (
  requestBody: RemoveHouseholdMemberRequestBody
): Promise<Response<undefined>> => {
  return httpClient.patch(routes.householdMember, snakecase(requestBody));
};
