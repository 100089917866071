import React from 'react';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

interface Props {
  open: boolean;
  handleClose: () => void;
  modalTitle: string;
}

export const Modal: React.FC<Props> = ({
  open,
  handleClose,
  modalTitle,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Styled.ModalContent>
        <Styled.CloseModalButton
          aria-label={t('Modal.close')}
          onClick={() => handleClose()}
        >
          <Close fontSize="small" />
        </Styled.CloseModalButton>
        <Styled.ModalTitle>{modalTitle}</Styled.ModalTitle>
        {children}
      </Styled.ModalContent>
    </Styled.Modal>
  );
};
