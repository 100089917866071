import { useEffect } from 'react';
import { digitsOnly } from '~/common/regex';
import { useTranslation } from 'react-i18next';
import { AccountInformationForm } from './types';
import { useGlobalState } from '~/context/global';
import { mapNotificationPreference } from '~/requests/user/mapping';
import { SelectOptions } from '~/components/forms/fields/Select';
import { TranslatedSelectField } from '~/common/types/translation';
import { useCountryPhoneCodes } from '~/state/hooks/useCountryPhoneCodes';
import { useForm, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { Race } from '~/context/global/user/account';
import {
  getDateOfBirthFromISO,
  removeCountryPhoneCodePrefix,
} from '~/common/generalUtils';
import {
  validateMaxAgeDateOfBirth,
  validateMinAgeDateOfBirth,
  validateFirstName,
} from '~/common/validations';

interface UseAccountInformationForm {
  form: UseFormMethods<AccountInformationForm>;
  validationSchema: Map<keyof AccountInformationForm, RegisterOptions>;
}

export const useAccountInformationForm = (): UseAccountInformationForm => {
  const { t } = useTranslation();
  const userAccount = useGlobalState().user.account;
  const countryPhoneCodes = useCountryPhoneCodes();

  const tPath = 'AccountSettings.form';

  const dateOfBirthInitialValue = userAccount.dateOfBirth
    ? getDateOfBirthFromISO(userAccount.dateOfBirth)
    : null;

  const genderOptions: SelectOptions = t(`${tPath}.gender.options`, {
    returnObjects: true,
  });

  const raceField: TranslatedSelectField = t(`${tPath}.raceId`, {
    returnObjects: true,
  });

  const ethnicityField: TranslatedSelectField = t(`${tPath}.ethnicityId`, {
    returnObjects: true,
  });

  const notificationPreferenceOptions: SelectOptions = t(
    `${tPath}.notifications.options`,
    { returnObjects: true }
  );

  const selectedNotificationOptionKey = mapNotificationPreference(
    userAccount?.remindByEmail,
    userAccount?.remindByPhone
  );

  const selectedNotificationOption = notificationPreferenceOptions.find(
    opt => opt.value === selectedNotificationOptionKey
  );

  // Removes the country code from the phone number before displaying it in the form.
  const formattedPhoneNumber = userAccount?.phoneNumber
    ? removeCountryPhoneCodePrefix(userAccount.phoneNumber, countryPhoneCodes)
    : '';

  const form = useForm<AccountInformationForm>({
    defaultValues: {
      nickname: userAccount?.nickname,
      dateOfBirth: dateOfBirthInitialValue,
      gender: userAccount?.gender,
      raceId: userAccount?.userRace?.race?.id,
      ethnicityId: userAccount?.userEthnicity?.ethnicity.id,
      userSubmittedRace: userAccount?.userRace?.userSubmittedRace,
      countryCode: userAccount?.countryCode,
      zipcode: userAccount?.zipcode,
      notificationPreference: selectedNotificationOption?.value,
      phoneNumber: formattedPhoneNumber,
    },
    reValidateMode: 'onBlur',
  });

  // Resets the form values once the user account data is fetched.
  useEffect(() => {
    const userAccountDataFetched = Object.values(userAccount).length;
    if (userAccountDataFetched) {
      form.reset({
        nickname: userAccount.nickname,
        dateOfBirth: dateOfBirthInitialValue,
        gender: userAccount.gender,
        raceId: userAccount.userRace?.race?.id,
        ethnicityId: userAccount.userEthnicity?.ethnicity.id,
        userSubmittedRace: userAccount.userRace?.userSubmittedRace,
        countryCode: userAccount.countryCode,
        zipcode: userAccount.zipcode,
        notificationPreference: selectedNotificationOption?.value,
        phoneNumber: formattedPhoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount]);

  const validationSchema = new Map<
    keyof AccountInformationForm,
    RegisterOptions
  >();

  validationSchema.set('nickname', {
    required: true,
    validate: validateFirstName,
  });

  validationSchema.set('gender', {
    required: true,
    validate: {
      options: value => genderOptions.some(opt => opt.value === value),
    },
  });

  validationSchema.set('raceId', {
    required: true,
    validate: {
      options: value => raceField.options.some(opt => opt.value === `${value}`),
    },
  });

  validationSchema.set('ethnicityId', {
    required: true,
    validate: {
      options: value =>
        ethnicityField.options.some(opt => opt.value === `${value}`),
    },
  });

  validationSchema.set('userSubmittedRace', {
    required: form.getValues().raceId?.toString() === Race.ANOTHER,
  });

  validationSchema.set('dateOfBirth', {
    required: true,
    validate: {
      minAge: value => validateMinAgeDateOfBirth(value),
      maxAge: value => validateMaxAgeDateOfBirth(value),
    },
  });

  validationSchema.set('countryCode', {
    required: true,
  });

  validationSchema.set('zipcode', {
    required: true,
  });

  validationSchema.set('notificationPreference', {
    required: true,
    validate: {
      options: value =>
        notificationPreferenceOptions.some(opt => opt.value === value),
    },
  });

  validationSchema.set('phoneNumber', {
    required: /text/i.test(form.getValues().notificationPreference),
    pattern: digitsOnly,
  });

  return { form, validationSchema };
};
