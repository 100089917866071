import { useTranslation } from 'react-i18next';
import { SelectOptions } from '~/components/forms/fields/Select';
import { TranslatedSelectField } from '~/common/types/translation';
import { useForm, RegisterOptions, UseFormMethods } from 'react-hook-form';
import {
  HouseholdMember,
  Gender,
  Race,
  Ethnicity,
} from '~/context/global/user/account';
import {
  defaultDateOfBirth,
  getDateOfBirthFromISO,
} from '~/common/generalUtils';
import {
  validateMaxAgeDateOfBirth,
  validateFirstName,
} from '~/common/validations';

export interface HouseholdMemberFormFields {
  name?: string;
  gender?: Gender;
  raceId?: Race;
  ethnicityId?: Ethnicity;
  userSubmittedRace?: string;
  dateOfBirth?: Date;
}

interface UseHouseholdMemberForm {
  form: UseFormMethods<HouseholdMemberFormFields>;
  validationSchema: Map<keyof HouseholdMemberFormFields, RegisterOptions>;
}

export const useHouseholdMemberForm = (
  member?: HouseholdMember
): UseHouseholdMemberForm => {
  const { t } = useTranslation();

  const tPath = 'HouseholdMembers.form';

  const genderOptions: SelectOptions = t(`${tPath}.gender.options`, {
    returnObjects: true,
  });

  const raceField: TranslatedSelectField = t(`${tPath}.raceId`, {
    returnObjects: true,
  });

  const ethnicityField: TranslatedSelectField = t(`${tPath}.ethnicityId`, {
    returnObjects: true,
  });

  const dateOfBirthInitialValue = member?.dateOfBirth
    ? getDateOfBirthFromISO(member.dateOfBirth)
    : defaultDateOfBirth;

  const form = useForm<HouseholdMemberFormFields>({
    defaultValues: {
      name: member?.name,
      gender: member?.gender,
      raceId: member?.userRace?.race?.id,
      userSubmittedRace: member?.userRace?.userSubmittedRace,
      ethnicityId: member?.userEthnicity?.ethnicity.id,
      dateOfBirth: dateOfBirthInitialValue,
    },
    mode: 'onBlur',
  });

  const validationSchema = new Map<
    keyof HouseholdMemberFormFields,
    RegisterOptions
  >();

  validationSchema.set('name', {
    required: true,
    validate: validateFirstName,
  });

  validationSchema.set('gender', {
    required: true,
    validate: {
      options: value => genderOptions.some(opt => opt.value === value),
    },
  });

  validationSchema.set('raceId', {
    required: true,
    validate: {
      options: value => raceField.options.some(opt => opt.value === `${value}`),
    },
  });

  validationSchema.set('userSubmittedRace', {
    required: form.getValues().raceId?.toString() === Race.ANOTHER,
  });

  validationSchema.set('ethnicityId', {
    required: true,
    validate: {
      options: value =>
        ethnicityField.options.some(opt => opt.value === `${value}`),
    },
  });

  validationSchema.set('dateOfBirth', {
    required: true,
    validate: {
      maxAge: value => validateMaxAgeDateOfBirth(value),
    },
  });

  return { form, validationSchema };
};
