import React, { useState } from 'react';
import { handleChangeEmail } from '~/requests/user';
import { useTranslation } from 'react-i18next';
import { updateUserAttributes } from '~/services/auth';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useAccountState } from '~/state/hooks/useAccountState';
import { useChangeEmail, ChangeEmailForm } from './useChangeEmail';
import { CardContent, CardActions, CircularProgress } from '@material-ui/core';
import { ReadOnlyInput, Input } from '~/components/AccountInformation/styles';
import { FormCard, CardTitle, SubmitButton } from './styles';

export const ChangeEmail: React.FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { form, validationSchema } = useChangeEmail();
  const { fetchAndUpdateAccountState } = useAccountState();

  const tPath = 'ChangeEmail';

  const submitForm = async ({ newEmail }: ChangeEmailForm) => {
    setLoading(true);
    try {
      await handleChangeEmail(newEmail);
      await updateUserAttributes({ email: newEmail });
      fetchAndUpdateAccountState();
      form.reset();
      form.setValue('currentEmail', newEmail);
      showSnackbar(t(`${tPath}.submitSuccess`), 'success');
    } catch (error) {
      showSnackbar(t(`${tPath}.submitError`), 'error');
    } finally {
      setLoading(false);
    }
  };

  const getErrorMessage = (fieldName: keyof ChangeEmailForm): string => {
    const errType = form.errors?.[fieldName]?.type;
    return errType ? t(`${tPath}.form.${fieldName}.errors.${errType}`) : '';
  };

  return (
    <>
      <CardTitle>{t(`${tPath}.cardTitle`)}</CardTitle>
      <FormCard>
        <CardContent>
          <ReadOnlyInput
            name="currentEmail"
            data-test="current_email_input"
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            label={t(`${tPath}.form.currentEmail.label`)}
            inputRef={form.register(validationSchema.get('currentEmail'))}
          />
          <Input
            name="newEmail"
            data-test="new_email_input"
            error={!!form.errors.newEmail}
            label={t(`${tPath}.form.newEmail.label`)}
            inputRef={form.register(validationSchema.get('newEmail'))}
            helperText={getErrorMessage('newEmail')}
          />
        </CardContent>
        <CardActions>
          <SubmitButton
            type="submit"
            onClick={form.handleSubmit(submitForm)}
            endIcon={loading && <CircularProgress color="inherit" size={18} />}
          >
            {t(`${tPath}.submitButton`)}
          </SubmitButton>
        </CardActions>
      </FormCard>
    </>
  );
};
