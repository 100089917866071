import styled from 'styled-components';
import {
  Card,
  Container,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';

export const PageTitle = styled(Typography).attrs({ variant: 'h4' })`
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 32px;
  }
`;

export const CardTitle = styled(Typography).attrs({ variant: 'h2' })`
  margin: 3rem 0 1.5rem;
  font-size: 24px;
  font-weight: 500;
`;

export const FormCard = styled(Card).attrs({ elevation: 1 })`
  max-width: 768px;
  padding: 0 1em 2em 1em;
`;

export const CenteredContainer = styled(Container).attrs({
  maxWidth: 'md',
  fixed: true,
})`
  padding: 2rem 0;
`;

export const SubmitButton = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
`;

export const PasswordInput: typeof TextField = styled(TextField).attrs({
  fullWidth: true,
  color: 'primary',
  type: 'password',
  variant: 'outlined',
})``;

export const EmailInput: typeof TextField = styled(PasswordInput).attrs({
  type: 'email',
  variant: 'outlined',
})``;
