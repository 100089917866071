import React from 'react';
import { PageProps } from 'gatsby';
import { withAuthGate } from '~/HOC/AuthGate';
import { AccountSettingsPage } from '~/components/AccountSettings';
import SEO from '~/components/seo';

const AccountSettings: React.FC<PageProps> = props => (
  <>
    <SEO title="AccountSettings.pageTitle" pageContext={props.pageContext} />
    <AccountSettingsPage {...props} />
  </>
);

export default withAuthGate(AccountSettings);
