import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

interface Props {
  showDifferentFromCurrent?: boolean;
}

export const PasswordRequirements: React.FC<Props> = ({
  showDifferentFromCurrent,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper>
      <Styled.Heading>{t('AccountPasswordFields.legend.title')}</Styled.Heading>
      <Styled.List>
        <Styled.ListItem>
          {t('AccountPasswordFields.legend.oneLetter')}
        </Styled.ListItem>
        <Styled.ListItem>
          {t('AccountPasswordFields.legend.oneNumber')}
        </Styled.ListItem>
        <Styled.ListItem>
          {t('AccountPasswordFields.legend.minimumLengthOfEight')}
        </Styled.ListItem>
        {showDifferentFromCurrent && (
          <Styled.ListItem>
            {t('AccountPasswordFields.legend.differentFromCurrent')}
          </Styled.ListItem>
        )}
      </Styled.List>
    </Styled.Wrapper>
  );
};

PasswordRequirements.defaultProps = {
  showDifferentFromCurrent: false,
};
