import styled from 'styled-components';
import { Card, Typography, Button } from '@material-ui/core';

export const CardTitle = styled(Typography).attrs({ variant: 'h2' })`
  margin: 3rem 0 1.5rem;
  font-size: 24px;
  font-weight: 500;
`;

export const FormCard = styled(Card).attrs({ elevation: 1 })`
  max-width: 768px;
  padding: 0 1em 2em 1em;
`;

export const SubmitButton = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
`;
