import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import { PageTitle } from './styles';
import { useTranslation } from 'react-i18next';
import { PageShell } from '~/components/BaseElements/pageShell';
import { AccountInformation } from '~/components/AccountInformation';
import { HouseholdMembersList } from '~/components/HouseholdMembers';
import { ChangePassword } from '~/components/ChangePassword';
import { ChangeEmail } from './ChangeEmail';
import { Vaccinations } from './Vaccinations';

export const AccountSettingsPage: React.FC<PageProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#household-members') {
      const element = document.getElementById('household-members');
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return (
    <PageShell>
      <PageTitle>{t('AccountSettings.pageTitle')}</PageTitle>
      <AccountInformation />
      <Vaccinations />
      <ChangeEmail />
      <ChangePassword />
      <HouseholdMembersList />
    </PageShell>
  );
};
