import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '~/context/global';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useAccountState } from '~/state/hooks/useAccountState';
import { useVaccinationsForm, VaccinationsForm } from './useVaccinationsForm';
import { CardContent, CardActions, CircularProgress } from '@material-ui/core';
import { FormCard, CardTitle, SubmitButton } from './styles';
import {
  getVaccineForField,
  getCurrentFluSeason,
  getFluVaccineCutoffYear,
  getCovidVaccineYear,
} from '~/common/vaccinations';
import {
  updateUserVaccinations,
  UpdateUserVaccinationsRequestBody,
} from '~/requests/user';
import {
  FluVaccineSelect,
  CovidVaccineSelect,
} from '~/components/forms/fields';

export const Vaccinations: React.FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { vaccinations } = useGlobalState().user.account;
  const { fetchAndUpdateAccountState } = useAccountState();
  const { form, validationSchema, getErrorMessage } = useVaccinationsForm();

  const tPath = 'AccountVaccinations';

  const filterUpdatedValues = (vaccinationsForm: VaccinationsForm) => {
    const updatedVaccinations: UpdateUserVaccinationsRequestBody = {};

    for (const vaccine in vaccinationsForm) {
      if (
        vaccinationsForm[vaccine] !==
        vaccinations[getVaccineForField(vaccine as keyof VaccinationsForm)]
      ) {
        updatedVaccinations[vaccine] = vaccinationsForm[vaccine];
      }
    }

    if (updatedVaccinations.receivedFluVaccine) {
      updatedVaccinations.fluSeason = getCurrentFluSeason();
    }

    if (updatedVaccinations.receivedCovidVaccine) {
      updatedVaccinations.covidSeason = getCovidVaccineYear();
    }

    return updatedVaccinations;
  };

  const submitForm = async (vaccinationsForm: VaccinationsForm) => {
    setLoading(true);
    try {
      const updatedVaccinations = filterUpdatedValues(vaccinationsForm);
      await updateUserVaccinations(updatedVaccinations);
      await fetchAndUpdateAccountState();
      showSnackbar(t(`${tPath}.submitSuccess`), 'success');
    } catch (error) {
      showSnackbar(t(`${tPath}.submitError`), 'error');
    } finally {
      setLoading(false);
    }
  };

  const fluVaccineCutoffYear = getFluVaccineCutoffYear();
  const fluVaccineSelectLabel = t('FormField.fluVaccine.label', {
    year: fluVaccineCutoffYear,
  });
  const covidVaccineLabel = t('FormField.covidVaccine.label', {
    year: getCovidVaccineYear(),
  });

  useEffect(
    function refreshForm() {
      form.reset({
        receivedFluVaccine: vaccinations?.flu,
        receivedCovidVaccine: vaccinations?.covid,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vaccinations]
  );

  return (
    <>
      <CardTitle>{t(`${tPath}.cardTitle`)}</CardTitle>
      <FormCard>
        <CardContent>
          <FluVaccineSelect
            formControl={form.control}
            label={fluVaccineSelectLabel}
            errorMessage={getErrorMessage('receivedFluVaccine')}
            validationRules={validationSchema.get('receivedFluVaccine')}
          />
          <CovidVaccineSelect
            formControl={form.control}
            errorMessage={getErrorMessage('receivedCovidVaccine')}
            validationRules={validationSchema.get('receivedCovidVaccine')}
            label={covidVaccineLabel}
          />
        </CardContent>
        <CardActions>
          <SubmitButton
            type="submit"
            disabled={!form.formState.isDirty}
            onClick={form.handleSubmit(submitForm)}
            endIcon={loading && <CircularProgress color="inherit" size={18} />}
            data-test="submitVaccinations"
          >
            {t(`${tPath}.submitButton`)}
          </SubmitButton>
        </CardActions>
      </FormCard>
    </>
  );
};
