import React, { useContext, Ref } from 'react';
import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { getCountryFlag } from '~/components/Header/MenuLanguage';
import { CountryContext } from '~/context/i18n';
import { FieldElement } from 'react-hook-form';
import { CountryCode } from 'utils/pageContext';
import styled from 'styled-components';

export const PhoneInputAdornment = styled(InputAdornment).attrs({
  position: 'start',
})`
  img {
    margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export const SmsAvailabilityMessage = styled(Typography).attrs({
  align: 'left',
  variant: 'body2',
})`
  margin: 0.5rem 0 1rem;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;

interface FlagAdornmentProps {
  countryCode: CountryCode;
  countryPhoneCode: string;
}

export const FlagAdornment: React.FC<FlagAdornmentProps> = ({
  countryCode,
  countryPhoneCode,
}) => (
  <PhoneInputAdornment>
    {getCountryFlag(countryCode)} {countryPhoneCode}
  </PhoneInputAdornment>
);

interface PhoneInputProps {
  label: string;
  error: boolean;
  inputRef: FieldElement<HTMLInputElement> & Ref<HTMLInputElement>;
  errorMessage?: string;
}

export const PhoneInput: React.FC<PhoneInputProps> = props => {
  const { country } = useContext(CountryContext);

  return (
    <TextField
      label={props.label}
      fullWidth
      type="tel"
      id="phoneNumber"
      name="phoneNumber"
      data-test="phone_input"
      aria-describedby="phone-helper-text"
      inputRef={props.inputRef}
      error={props.error}
      InputProps={{
        startAdornment: (
          <FlagAdornment
            countryCode={country.code}
            countryPhoneCode={country.phoneCode}
          />
        ),
      }}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      helperText={props.errorMessage}
    />
  );
};
