export enum NotificationPreference {
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
  EMAIL_AND_TEXT = 'EMAIL_AND_TEXT',
  NO_REMINDERS = 'NO_REMINDERS',
}

export const mapNotificationPreference = (
  remindByEmail: boolean,
  remindByPhone: boolean
): NotificationPreference => {
  if (remindByEmail && remindByPhone) {
    return NotificationPreference.EMAIL_AND_TEXT;
  } else if (remindByEmail) {
    return NotificationPreference.EMAIL;
  } else if (remindByPhone) {
    return NotificationPreference.TEXT;
  } else {
    return NotificationPreference.NO_REMINDERS;
  }
};
