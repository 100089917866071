import React, { useState } from 'react';
import { useGlobalDispatch } from '~/context/global';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { HouseholdMember } from '~/context/global/user/account';
import { HouseholdMemberFormFields } from './useHouseholdMemberForm';
import { HouseholdMemberForm } from './HouseholdMemberForm';
import { convertDateToISO } from '~/common/generalUtils';
import {
  addHouseholdMember,
  editHouseholdMember,
  AddHouseholdMemberRequestBody,
  EditHouseholdMemberRequestBody,
} from '~/requests/household';
import { VaccinationValue } from '~/common/vaccinations';

interface Props {
  open: boolean;
  handleClose: () => void;
  member?: HouseholdMember;
}

export const HouseholdMemberModal: React.FC<Props> = ({
  open,
  member,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const dispatch = useGlobalDispatch();
  const { t } = useTranslation();

  const isEditing = !!member;
  const tPath = 'HouseholdMembers.form';
  const action = isEditing ? 'editHouseholdMember' : 'addHouseholdMember';

  const submitAddHouseholdMember = async (
    values: HouseholdMemberFormFields
  ) => {
    setLoading(true);
    try {
      const requestBody: AddHouseholdMemberRequestBody = {
        name: values.name,
        gender: values.gender,
        raceId: values.raceId,
        userSubmittedRace: values.userSubmittedRace,
        dateOfBirth: convertDateToISO(values.dateOfBirth),
        ethnicityId: values.ethnicityId,
      };

      const { data, status } = await addHouseholdMember(requestBody);
      if (status !== 201) {
        throw Error();
      }

      const newHouseholdMember = {
        ...data,
        vaccinations: {
          flu: VaccinationValue.UNKNOWN,
          covid: VaccinationValue.UNKNOWN,
        },
      };

      dispatch({ type: 'ADD_HOUSEHOLD_MEMBER', payload: newHouseholdMember });
      showSnackbar(
        t(`${tPath}.householdMemberSuccess`, {
          memberName: values.name,
          action: 'added',
        }),
        'success'
      );
      handleClose();
    } catch (error) {
      showSnackbar(
        t(`${tPath}.householdMemberError`, {
          memberName: values.name,
          action: 'add',
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const submitEditHouseholdMember = async (
    values: HouseholdMemberFormFields
  ) => {
    setLoading(true);
    try {
      const requestBody: EditHouseholdMemberRequestBody = {
        id: member.id,
        name: values.name,
        gender: values.gender,
        raceId: values.raceId,
        ethnicityId: values.ethnicityId,
        userSubmittedRace: values.userSubmittedRace,
        dateOfBirth: convertDateToISO(values.dateOfBirth),
      };

      const { data, status } = await editHouseholdMember(requestBody);
      if (status !== 200) throw Error();

      dispatch({ type: 'EDIT_HOUSEHOLD_MEMBER', payload: data });
      showSnackbar(
        t(`${tPath}.householdMemberSuccess`, {
          memberName: values.name,
          action: 'updated',
        }),
        'success'
      );
      handleClose();
    } catch (error) {
      showSnackbar(
        t(`${tPath}.householdMemberError`, {
          memberName: values.name,
          action: 'update',
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      modalTitle={t(`${tPath}.${action}FormTitle`)}
    >
      <HouseholdMemberForm
        submitForm={
          isEditing ? submitEditHouseholdMember : submitAddHouseholdMember
        }
        handleClose={handleClose}
        loading={loading}
        member={member}
      />
    </Modal>
  );
};
