import styled from 'styled-components';
import { Card, Theme, Typography, TextField, Button } from '@material-ui/core';
import { Select as UnstyledSelect } from '~/components/forms/fields/Select';

export const CardTitle = styled(Typography).attrs({ variant: 'h2' })`
  margin: 3rem 0 1.5rem;
  font-size: 24px;
  font-weight: 500;
`;

export const FormCard = styled(Card).attrs({ elevation: 1 })`
  max-width: 768px;
  padding: 0 1em 2em 1em;
`;

export const SubmitButton = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
`;

export const Select = styled(UnstyledSelect).attrs({
  variant: 'outlined',
})``;

export const Input: typeof TextField = styled(TextField).attrs({
  fullWidth: true,
  color: 'primary',
  type: 'text',
  variant: 'outlined',
})``;

export const ReadOnlyInput: typeof TextField = styled(TextField).attrs({
  fullWidth: true,
  type: 'text',
  variant: 'outlined',
})`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .MuiFormLabel-root {
    &.Mui-focused {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid
      ${({ theme }: { theme: Theme }) => theme.palette.text.disabled};
  }
  .MuiInput-underline:after {
    border-bottom: 0;
    &.Mui-hover {
      transform: none;
    }
    &.Mui-focused {
      transform: none;
    }
  }
`;
