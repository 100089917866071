import styled from 'styled-components';
import {
  Card as MuiCard,
  Button,
  Table as MuiTable,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import { Select as UnstyledSelect } from '~/components/forms/fields/Select';

export const CardTitle = styled(Typography).attrs({ variant: 'h2' })`
  margin: 3rem 0 1.5rem;
  font-size: 24px;
  font-weight: 500;
`;

export const Card = styled(MuiCard).attrs({ elevation: 1 })`
  max-width: 768px;
  padding: 2rem;
`;

export const Description = styled(Typography)`
  margin: 0 0 1rem;
  font-size: 0.875rem;
`;

export const AddMemberButton = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
  margin-top: 1rem;
`;

export const SaveButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
`;

export const ScrollTopButton = styled(Button).attrs({
  variant: 'text',
})`
  display: inline-block;
  margin: 0;
  padding: 0;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
  color: ${props => props.theme.palette.primary.main};
  vertical-align: baseline;
  background-color: transparent;
  .MuiButton-label {
    text-decoration: underline;
  }
  &:focus,
  &:hover {
    background-color: transparent;
    .MuiButton-label {
      color: ${props => props.theme.palette.primary.dark200};
    }
  }
`;

export const CancelButton = styled(Button).attrs({
  variant: 'text',
})`
  margin-left: 0.5rem;
  color: ${props => props.theme.palette.gray.mid};
`;

export const Table = styled(MuiTable)`
  margin: 1rem 0;
  overflow-x: scroll;
`;

export const TableHeading = styled(TableCell)`
  font-weight: bold;
`;

export const FormActions = styled.div`
  margin: 1.5rem 0 0;
`;

export const Input: typeof TextField = styled(TextField).attrs({
  fullWidth: true,
  color: 'primary',
  type: 'text',
  variant: 'outlined',
})``;

export const Select = styled(UnstyledSelect).attrs({
  variant: 'outlined',
})``;
