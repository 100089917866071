import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import {
  useHouseholdMemberForm,
  HouseholdMemberFormFields,
} from './useHouseholdMemberForm';
import { HouseholdMember } from '~/context/global/user/account';
import { CircularProgress } from '@material-ui/core';
import { SelectOptions } from '~/components/forms/fields/Select';
import { DateOfBirthInput } from '~/components/forms/fields/DateOfBirthInput';
import { RaceSelect } from '~/components/forms/fields/RaceSelect';
import { oldestBirthYear } from '~/common/generalUtils';
import * as Styled from './styles';
import { EthnicitySelect } from '~/components/forms/fields/EthnicitySelect';

interface Props {
  loading: boolean;
  handleClose: () => void;
  submitForm: (values: HouseholdMemberFormFields) => void;
  member?: HouseholdMember;
}

export const HouseholdMemberForm: React.FC<Props> = ({
  submitForm,
  handleClose,
  loading,
  member,
}) => {
  const { t } = useTranslation();
  const tPath = 'HouseholdMembers.form';

  const { form, validationSchema } = useHouseholdMemberForm(member);

  const dateOfBirth = form.watch('dateOfBirth');

  const genderOptions: SelectOptions = t(`${tPath}.gender.options`, {
    returnObjects: true,
  });

  const getErrorMessage = (
    fieldName: keyof HouseholdMemberFormFields,
    errType?: string
  ): string => {
    if (!errType) {
      return '';
    }

    if (errType === 'maxAge') {
      return t(`${tPath}.${fieldName}.errors.${errType}`, {
        oldestBirthYear,
      });
    }

    if (
      fieldName === 'userSubmittedRace' ||
      fieldName === 'raceId' ||
      fieldName === 'ethnicityId'
    ) {
      return t(`FormField.${fieldName}.errors.${errType}`);
    }

    return t(`${tPath}.${fieldName}.errors.${errType}`);
  };

  form.register('dateOfBirth', validationSchema.get('dateOfBirth'));

  return (
    <FormProvider {...form}>
      <Styled.Input
        name="name"
        data-cy="household-member-name-input"
        error={!!form.errors.name}
        label={t(`${tPath}.name.label`)}
        inputRef={form.register(validationSchema.get('name'))}
        helperText={getErrorMessage('name', form.errors?.name?.type)}
      />
      <Styled.Select
        name="gender"
        variant="outlined"
        data-cy="household-member-gender-select"
        options={genderOptions}
        formControl={form.control}
        label={t(`${tPath}.gender.label`)}
        validationRules={validationSchema.get('gender')}
        errorMessage={getErrorMessage('gender', form.errors?.gender?.type)}
        showBlankDefault
      />
      <RaceSelect
        data-cy="household-member-race-select"
        form={form}
        getErrorMessage={getErrorMessage}
        validationSchema={validationSchema}
      />
      <EthnicitySelect
        data-cy="household-member-ethnicity-select"
        form={form}
        getErrorMessage={getErrorMessage}
        validationSchema={validationSchema}
      />
      <DateOfBirthInput
        name="dateOfBirth"
        data-cy="household-member-date-of-birth-input"
        value={dateOfBirth}
        error={!!form.errors.dateOfBirth}
        label={t(`${tPath}.dateOfBirth.label`)}
        onChange={date =>
          form.setValue('dateOfBirth', date, { shouldValidate: true })
        }
        helperText={getErrorMessage(
          'dateOfBirth',
          form.errors?.dateOfBirth?.type
        )}
      />
      <Styled.FormActions>
        <Styled.SaveButton
          data-cy="household-member-save-button"
          onClick={form.handleSubmit(submitForm)}
          endIcon={loading && <CircularProgress color="inherit" size={18} />}
        >
          {t(`${tPath}.saveButtonLabel`)}
        </Styled.SaveButton>
        <Styled.CancelButton onClick={handleClose}>
          {t(`${tPath}.cancelButtonLabel`)}
        </Styled.CancelButton>
      </Styled.FormActions>
    </FormProvider>
  );
};
