import React, { useState } from 'react';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { changePassword } from '~/services/auth';
import { CardTitle, FormCard, PasswordInput, SubmitButton } from './styles';
import { CardContent, CardActions, CircularProgress } from '@material-ui/core';
import { useChangePassword, ChangePasswordForm } from './useChangePassword';
import { PasswordRequirements } from '../forms/PasswordRequirements';

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { form, validationSchema } = useChangePassword();

  const submitForm = async ({
    newPassword,
    currentPassword,
  }: ChangePasswordForm) => {
    setLoading(true);
    try {
      await changePassword(currentPassword, newPassword);
      showSnackbar(t('ChangePassword.submitSuccess'), 'success');
      form.reset();
    } catch (error) {
      showSnackbar(t('ChangePassword.submitError'), 'error');
    } finally {
      setLoading(false);
    }
  };

  const getErrorMessage = (
    fieldName: keyof ChangePasswordForm,
    errType?: string
  ): string => {
    return errType
      ? t(`AccountPasswordFields.${fieldName}.errors.${errType}`)
      : '';
  };

  return (
    <>
      <CardTitle>{t('ChangePassword.heading')}</CardTitle>
      <FormCard>
        <CardContent>
          <PasswordInput
            name="currentPassword"
            data-test="currentPassword_input"
            error={!!form.errors.currentPassword}
            label={t('AccountPasswordFields.currentPassword.label')}
            inputRef={form.register(validationSchema.get('currentPassword'))}
            helperText={getErrorMessage(
              'currentPassword',
              form.errors?.currentPassword?.type
            )}
          />
          <PasswordInput
            name="newPassword"
            data-test="newPassword_input"
            error={!!form.errors.newPassword}
            label={t('AccountPasswordFields.newPassword.label')}
            inputRef={form.register(validationSchema.get('newPassword'))}
            helperText={getErrorMessage(
              'newPassword',
              form.errors?.newPassword?.type
            )}
          />
          <PasswordInput
            name="confirmPassword"
            data-test="confirmPassword_input"
            error={!!form.errors.confirmPassword}
            label={t('AccountPasswordFields.confirmPassword.label')}
            inputRef={form.register(validationSchema.get('confirmPassword'))}
            helperText={getErrorMessage(
              'confirmPassword',
              form.errors?.confirmPassword?.type
            )}
          />
          <PasswordRequirements showDifferentFromCurrent />
        </CardContent>
        <CardActions>
          <SubmitButton
            type="submit"
            data-cy="change-password-submit-button"
            onClick={form.handleSubmit(submitForm)}
            endIcon={loading && <CircularProgress color="inherit" size={18} />}
          >
            {t('ChangePassword.submitButton')}
          </SubmitButton>
        </CardActions>
      </FormCard>
    </>
  );
};
