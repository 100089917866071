import { useForm, RegisterOptions, UseFormMethods } from 'react-hook-form';

export interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface UseChangePassword {
  form: UseFormMethods<ChangePasswordForm>;
  validationSchema: Map<keyof ChangePasswordForm, RegisterOptions>;
}

export const useChangePassword = (): UseChangePassword => {
  const form = useForm<ChangePasswordForm>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const validationSchema = new Map<keyof ChangePasswordForm, RegisterOptions>();
  validationSchema.set('currentPassword', { required: true });
  validationSchema.set('newPassword', {
    required: true,
    minLength: 8,
    validate: {
      number: value => /[\d]{1}/.test(value),
      letter: value => /[a-zA-Z]/.test(value),
      sameAsOld: value => value !== form.getValues('currentPassword'),
    },
  });
  validationSchema.set('confirmPassword', {
    required: true,
    validate: {
      match: value => value === form.getValues('newPassword'),
    },
  });

  return { form, validationSchema };
};
